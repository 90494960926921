.App {
  height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.header {
  width: 100%;
  text-align: center;
}

.App-logo {
  margin: 1rem 0;
  width: 17rem;
}

.container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  border-top: 1px solid #d4d4d4;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.design {
  width: 100%;
  margin-left: 1rem;
  overflow: hidden;
}

.selectsbol {
  font-size: 2rem;
  color: #888888;
}

.sboltext {
  padding: 2rem;
  background-color: #faf9b1;
  border-radius: 1rem;
  filter: drop-shadow(0px 0px 2px #888888);
  line-height: 130%;
}

.editorheaders {
  display: flex;
  justify-content: space-between;
}

.editorheader {
  font-size: 1rem;
  color: #5a5900;
  padding: 0.6rem;
  border-top: 1px solid #5a5900;
  border-left: 1px solid #5a5900;
  border-right: 1px solid #5a5900;
  border-radius: 0.2rem 0.2rem 0 0;
  cursor: pointer;
  background-color: white;
}

.editorheaderselected {
  cursor: default !important;
  background-color: #e0dd002a !important;
}

.editorcontainer {
  height: 100%;
}

.fileinput {
  margin-bottom: 0.5rem;
}